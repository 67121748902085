
import Vue, { PropOptions } from 'vue';
import { NeWidgetStaticBanners } from '@ne/ne-vue2-lib';
import { StaticBanners, Widget } from '@ne/ne-mul-interface-api';

export default Vue.extend({
  name: 'WidgetStaticBanners',
  components: {
    NeWidgetStaticBanners
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<StaticBanners>>
  },
  data () {
    return {
      lineColor: 'grey',
      lineIconColor: 'primary',
      icon: 'arrow-bottom'
    };
  }
});
