
import Vue, { PropOptions } from 'vue';
import { NeWidgetFilesList } from '@ne/ne-vue2-lib';
import { FilesList } from '@/models/widgets/files-list';

interface File {
  description: string;
  fileType: string;
  url: string;
}

export default Vue.extend({
  name: 'FilesList',
  components: {
    NeWidgetFilesList
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<FilesList>
  },
  computed: {
    files (): File[] {
      const fileList: File[] = [];
      this.data.files.forEach((element) => {
        fileList.push(
          {
            description: element.title || '',
            url: element.url,
            fileType: this.getFileIcon(element.icon)
          }
        );
      });
      return fileList;
    }
  },
  methods: {
    getFileIcon (icon: string): string {
      const iconList:string[] = [
        'uni',
        'doc',
        'docx',
        'exe',
        'jpg',
        'mp3',
        'mp4',
        'pdf',
        'png',
        'ppt',
        'pptx',
        'rar',
        'xls',
        'xlsx',
        'zip'
      ];
      let fileType = icon;
      if (!iconList.includes(fileType)) {
        fileType = 'uni';
      }
      return fileType;
    }
  }
});
