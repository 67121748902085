
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NeSpinner } from '@ne/ne-vue2-lib';
import { TranslateResult } from 'vue-i18n';

@Component({
  name: 'LoadingBlend',
  components: {
    NeSpinner
  }
})
export default class LoadingBlend extends Vue {
  @Prop() text: TranslateResult;
  @Prop({ default: 20 }) loaderSize: number;
  @Prop({ default: 'row' }) direction: string;

  get classes (): any[] {
    return ['gt-loading-blend', `gt-loading-blend--${this.direction}`];
  }
}
