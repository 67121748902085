
import Vue, { PropOptions } from 'vue';
import { NeWidgetESolutions } from '@ne/ne-vue2-lib';
import { ESolutions, Item, Level } from '@/models/widgets/e-solutions';

export default Vue.extend({
  name: 'WidgetESolutions',
  components: {
    NeWidgetESolutions
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<ESolutions>
  },
  data () {
    return {
      items: [] as Item[]
    };
  },
  mounted () {
    this.mapESolutionItems();
  },
  methods: {
    mapESolutionItems () {
      this.items = this.data.levels.map((level: Level) => {
        return {
          alt: level.images[0].alt,
          lead: level.images[0].lead,
          src: level.images[0].src.default,
          newWindow: level.images[0].targetBlank,
          title: level.images[0].title,
          href: level.images[0].url,
          btnName: level.btnName
        };
      });
    }
  }
});
