
import Vue, { PropOptions } from 'vue';
import { NeWidgetTopCarousel, NeWidgetTopCarouselSlide, NeWidgetTopCarouselTile } from '@ne/ne-vue2-lib';
import { Slider, staticSliderLabels } from '@/models/widgets/slider';

export default Vue.extend({
  name: 'WidgetCarousel',
  components: {
    NeWidgetTopCarousel,
    NeWidgetTopCarouselSlide,
    NeWidgetTopCarouselTile
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Slider>
  },
  data () {
    return {
      socials: [
        { name: 'facebook', iconName: 'facebook', url: 'https://www.facebook.com/nowaerapl/' },
        { name: 'twitter', iconName: 'twitter', url: 'https://twitter.com/nowaerapl' },
        { name: 'youtube', iconName: 'youtube', url: 'https://www.youtube.com/user/WydawnictwoNowaEra' }
      ]
    };
  },
  methods: {
    isUppercase (buttonStyle: string): boolean {
      return buttonStyle.indexOf(staticSliderLabels.BIG) > 0;
    },
    getColor (buttonStyle: string): string {
      let color = 'primary';
      if (buttonStyle.indexOf(staticSliderLabels.ORANGE) > 0) {
        color = staticSliderLabels.ORANGE;
      } else if (buttonStyle.indexOf(staticSliderLabels.GREEN) > 0) {
        color = staticSliderLabels.GREEN;
      }
      return color;
    }
  }
});
