
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ExerciseTeacherComment, Source, TeacherCommentAnswerEnum } from '@/models/exercises';
import { NeButton, NeTextField } from '@ne/ne-vue2-lib';
import { EXERCISE_COMMENTS } from '@/api/eob/endpoints';

@Component({
  name: 'ExerciseComment',
  components: { NeButton, NeTextField }
})
export default class ExerciseComment extends Vue {
  @Prop() variantId: number;
  @Prop() exerciseId: number;
  @Prop() exerciseComment: ExerciseTeacherComment;
  @Prop() author: Source;

  source = Source;
  teacherCommentAnswer = TeacherCommentAnswerEnum;
  exerciseCommentCopy = this.exerciseComment;

  @Watch('variantId')
  handleVariantChange () {
    this.exerciseCommentCopy = this.exerciseComment;
    if (this.exerciseCommentCopy.teacherAnswer) {
      this.exerciseCommentCopy.isSent = [TeacherCommentAnswerEnum.YES, TeacherCommentAnswerEnum.NO].includes(this.exerciseCommentCopy.teacherAnswer as TeacherCommentAnswerEnum);
    }
  }

  get currentCommentAnswer () {
    return this.exerciseCommentCopy.teacherAnswer;
  }

  showConfirmation () {
    this.exerciseCommentCopy.isSent = true;
  }

  setAnswer (answer: TeacherCommentAnswerEnum): void {
    this.exerciseCommentCopy.teacherAnswer = answer;
    if (answer === TeacherCommentAnswerEnum.NO) {
      this.showConfirmation();
    }
    answer === TeacherCommentAnswerEnum.YES ? this.sendComment() : this.saveExerciseCommentCookie(this.exerciseCommentCopy, true);
  }

  async sendComment (isSendButtonClicked = false): Promise<void> {
    try {
      const commentToSave = {
        comment: this.exerciseCommentCopy.comment || null,
        exerciseId: this.exerciseComment.exerciseId,
        variantId: this.exerciseComment.variantId
      };
      const response = (await this.$api.post<any>(EXERCISE_COMMENTS, commentToSave)).data;
      if (response) {
        this.saveExerciseCommentCookie(this.exerciseCommentCopy, true);
        if (isSendButtonClicked) {
          this.showConfirmation();
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }

  saveExerciseCommentCookie (commentCookie: ExerciseTeacherComment, isSent = false): void {
    const date = new Date();
    const expirationDate = date.setDate(date.getDate() + 7);
    this.$cookies.set(
      `comment_${this.variantId}`,
      { ...commentCookie, isSent },
      new Date(expirationDate)
    );
  }
}
