
import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import TinyMceEditor from '@tinymce/tinymce-vue';
import {
  EditorButton,
  EditorPlugin
} from './models';
import { resourcePath } from '@/config';

@Component({
  name: 'TextEditor',
  components: {
    TinyMceEditor
  }
})
export default class TextEditor extends Vue {
  $refs: {
    editor: any;
  };

  @Prop() cssAssets: any;
  @Prop() disabled!: boolean;
  @Prop() editorId!: string;
  @Prop() plugins: EditorPlugin[];
  @Prop() toolbarButtons: EditorButton[][];
  @Prop() value!: string;

  @Prop({ default: '', type: String }) bodyClasses: string;
  @Prop({ default: () => {} }) attributes: {[key: string]: any};
  @PropSync('editor') editorInstance: any;

  objectId: number = Math.floor(Math.random() * 100000000);

  get editorConfig () {
    const activeToolbarButtons = this.toolbarButtons.map(toolbar => {
      return toolbar.filter(b => !b.disabled);
    });

    const toolbars: {[key: string]: string} = {};
    let toolbarIndex = 0;
    let customAttributes = {
      ...this.attributes
    };

    const pluginNames: string[] = [];
    const externalPlugins: {[key: string]: string} = {};
    for (const plugin of this.plugins) {
      if (!plugin.disabled) {
        pluginNames.push(plugin.name);
        if (plugin.path) externalPlugins[plugin.name] = `${resourcePath}${plugin.path}`;
        if (plugin.attributes) {
          customAttributes = {
            ...customAttributes,
            ...plugin.attributes
          };
        }
      }
    }
    for (const buttons of activeToolbarButtons) {
      toolbarIndex++;
      toolbars[`toolbar${toolbarIndex}`] = buttons.map(b => b.name).join(' ');
      for (const button of buttons) {
        if (button.attributes) {
          customAttributes = {
            ...customAttributes,
            ...button.attributes
          };
        }
        if (button.hasPlugin) pluginNames.push(button.name);
        if (button.pluginPath) externalPlugins[button.name] = `${resourcePath}${button.pluginPath}`;
      }
    }
    return {
      ...toolbars,
      autoresize_min_height: 300,
      baseUrl: '',
      body_class: this.bodyClasses,
      branding: false,
      cache_suffix: `?v=${this.objectId}`,
      content_css: this.cssAssets,
      convert_urls: false,
      document_base_url: '',
      elementpath: false,
      entity_encoding: 'raw',
      extended_valid_elements: 'table[class|id|width|height], td[class|id|width|height], em[*]',
      external_plugins: externalPlugins,
      force_br_newlines: false,
      force_p_newlines: true,
      forced_root_block: 'p',
      image_caption: false,
      language: 'pl',
      language_url: `${resourcePath}assets/tinymce/langs/pl.js`,
      menubar: false,
      plugin_preview_height: 700,
      plugin_preview_width: 1100,
      plugins: pluginNames,
      relative_urls: false,
      remove_script_host: false,
      resize: true,
      skin: 'ne-tiny',
      skin_url: `${resourcePath}assets/tinymce/skins/ne-tiny`,
      statusbar: true,
      theme: 'silver',
      theme_advanced_path: false,
      theme_advanced_statusbar_location: '',
      toolbar_items_size: 'small',
      valid_elements: '*[*]',
      width: 680,

      setup: (editor: any) => {
        this.addInsertAnswerMenuItem(editor);
        for (const buttons of this.toolbarButtons) {
          for (const button of buttons) {
            if (button.isCustom) {
              if (button.menu) {
                for (const menuItem of button.menu) {
                  if (menuItem.event) {
                    menuItem.onclick = function () {
                      menuItem.event(editor);
                    };
                  }
                }
              }
              editor.ui.registry.addButton(button.name, {
                image: button.image ? `${resourcePath}${button.image}` : undefined,
                icon: button.icon,
                text: button.text,
                tooltip: button.tooltip,
                type: button.type,
                menu: button.menu,
                onclick: function () {
                  if (button.event) {
                    button.event(editor);
                  }
                },
                onPostRender: function () {
                  const _this = this; // reference to the button itself
                  if (button.onPostRender) {
                    button.onPostRender(editor, _this);
                  }
                }
              });
            }
          }
        }
      },
      ...customAttributes
    };
  }

  addInsertAnswerMenuItem (editor: any): void {
    const self = this;
    editor.ui.registry.addMenuItem('insert-answer', {
      text: this.$tc('EXERCISE_ANSWER.copy_to_empty_field'),
      context: 'newmenu',
      icon: 'copy',
      onclick: function () {
        self.onInsertAnswer(editor.selection.getContent({ format: 'HTML' }));
      }
    });
  }

  @Emit()
  onInsertAnswer (answer: string): string {
    return answer;
  }

  @Emit() input (content: string): string {
    return content;
  }

  @Emit() blur () {}

  @Emit() change () {}

  @Emit() init () {
    this.editorInstance = this.$refs.editor;
  }
}
