
import Vue, { PropOptions } from 'vue';
import { NeWidgetClickableInformationTiles } from '@ne/ne-vue2-lib';
import { ClickableInfoTiles, Widget } from '@ne/ne-mul-interface-api';

export default Vue.extend({
  name: 'WidgetClickableInfoTiles',
  components: {
    NeWidgetClickableInformationTiles
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<ClickableInfoTiles>>
  }
});
