
import Vue, { PropOptions } from 'vue';
import { ApiWidget, MulWidgetType, WidgetType } from '@/models/widgets';
import WidgetCarousel from './WidgetCarousel/WidgetCarousel.vue';
import WidgetFilesList from './WidgetFilesList/WidgetFilesList.vue';
import WidgetRotator from './WidgetRotator/WidgetRotator.vue';
import WidgetMultimedia from './WidgetMultimedia/WidgetMultimedia.vue';
import WidgetArticleBlock from './WidgetArticleBlock/WidgetArticleBlock.vue';
import WidgetESolutions from './WidgetESolutions/WidgetESolutions.vue';
import WidgetAdvancedText from './WidgetAdvancedText/WidgetAdvancedText.vue';
import WidgetIllustration from './WidgetIllustration/WidgetIllustration.vue';
import WidgetDynamicBanners from './WidgetDynamicBanners/WidgetDynamicBanners.vue';
import WidgetGeneratorStats from './WidgetGeneratorStats/WidgetGeneratorStats.vue';
import WidgetStaticBanners from '@/shared/components/DynamicWidget/WidgetStaticBanners/WidgetStaticBanners.vue';
import WidgetFaq from '@/shared/components/DynamicWidget/WidgetFaq/WidgetFaq.vue';
import WidgetForm from '@/shared/components/DynamicWidget/WidgetForm/WidgetForm.vue';
import WidgetGraphic from '@/shared/components/DynamicWidget/WidgetGraphic/WidgetGraphic.vue';
import WidgetGallery from '@/shared/components/DynamicWidget/WidgetGallery/WidgetGallery.vue';
import WidgetArticleIndex from '@/shared/components/DynamicWidget/WidgetArticleIndex/WidgetArticleIndex.vue';
import WidgetCascadeTiles from '@/shared/components/DynamicWidget/WidgetCascadeTiles/WidgetCascadeTiles.vue';
import WidgetReversibleTiles from '@/shared/components/DynamicWidget/WidgetReversibleTiles/WidgetReversibleTiles.vue';
import WidgetClickableInfoTiles
  from '@/shared/components/DynamicWidget/WidgetClickableInfoTiles/WidgetClickableInfoTiles.vue';
import WidgetTextGraphicButtons
  from '@/shared/components/DynamicWidget/WidgetTextGraphicButtons/WidgetTextGraphicButtons.vue';
import WidgetVideo from '@/shared/components/DynamicWidget/WidgetVideo/WidgetVideo.vue';
import WidgetAttachments from '@/shared/components/DynamicWidget/WidgetAttachments/WidgetAttachments.vue';
import WidgetAdvancedTextMul from '@/shared/components/DynamicWidget/WidgetAdvancedTextMul/WidgetAdvancedTextMul.vue';

export default Vue.extend({
  name: 'DynamicWidget',
  props: {
    widget: {
      type: Object,
      required: true
    } as PropOptions<ApiWidget>
  },
  data () {
    return {
      componentsMap: {
        [WidgetType.ROTATOR]: WidgetRotator,
        [WidgetType.SLIDER]: WidgetCarousel,
        [WidgetType.FILES_LIST]: WidgetFilesList,
        [WidgetType.MULTIMEDIA]: WidgetMultimedia,
        [WidgetType.ARTICLE_BLOCK]: WidgetArticleBlock,
        [WidgetType.E_SOLUTIONS]: WidgetESolutions,
        [WidgetType.ADVANCED_TEXT]: WidgetAdvancedText,
        [WidgetType.ILLUSTRATION]: WidgetIllustration,
        [WidgetType.DYNAMIC_BANNERS]: WidgetDynamicBanners,
        [WidgetType.GENERATOR_STATS]: WidgetGeneratorStats,
        [MulWidgetType.STATIC_BANNERS]: WidgetStaticBanners,
        [MulWidgetType.FAQ]: WidgetFaq,
        [MulWidgetType.FORM]: WidgetForm,
        [MulWidgetType.GALLERY]: WidgetGallery,
        [MulWidgetType.GRAPHIC]: WidgetGraphic,
        [MulWidgetType.ARTICLE_INDEX]: WidgetArticleIndex,
        [MulWidgetType.CASCADE_TILES]: WidgetCascadeTiles,
        [MulWidgetType.REVERSIBLE_TILES]: WidgetReversibleTiles,
        [MulWidgetType.CLICKABLE_INFO_TILE]: WidgetClickableInfoTiles,
        [MulWidgetType.TEXT_GRAPHIC_BUTTONS]: WidgetTextGraphicButtons,
        [MulWidgetType.VIDEO]: WidgetVideo,
        [MulWidgetType.ADVANCED_TEXT]: WidgetAdvancedTextMul,
        [MulWidgetType.ATTACHMENTS]: WidgetAttachments
      } as { [key: string]: any }
    };
  },
  methods: {
    getComponent (type: string) {
      return this.componentsMap[type] || 'div';
    }
  }
});
