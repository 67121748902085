
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NeAutocomplete } from '@ne/ne-vue2-lib';
import { AutocompleteItem } from './models';

@Component({
  name: 'Autocomplete',
  components: {
    NeAutocomplete
  }
})
export default class GtAutocomplete extends Vue {
  @Prop() value!: string | number;
  @Prop() items!: AutocompleteItem[];
  @Prop() width?: number;

  @Emit() leave () :void {}

  @Emit() select (item: AutocompleteItem): AutocompleteItem {
    return item;
  }

  @Emit() input (value: string | number): string | number {
    return value;
  }
}
