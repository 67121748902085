
import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator';
import {
  NeModal
} from '@ne/ne-vue2-lib';

@Component({
  name: 'Modal',
  components: {
    NeModal
  }
})
export default class Modal extends Vue {
  @Prop() value: boolean;
  @Prop({ default: null }) size: string | null;
  @Prop({ default: null }) width: number | null;
  @Prop({ default: null }) lockCookieName: string | null;
  @Prop() unlockTime: string | number | Date | undefined;
  @Prop({ default: false, type: Boolean }) dismissable: boolean;
  isLockCookieSet: boolean = false;

  @Watch('value')
  changeValueHandler (isOpened: boolean) {
    const cookie = this.lockCookieName ? this.$cookies.get(this.lockCookieName) : null;
    if (isOpened) {
      this.isLockCookieSet = cookie && cookie === 'true';
    } else if (!cookie && this.lockCookieName && this.unlockTime) {
      this.$cookies.set(this.lockCookieName, 'true', this.unlockTime);
    }
  }

  @Emit()
  input (value: boolean): boolean {
    return value;
  }

  close (): void {
    this.input(false);
  }
}
