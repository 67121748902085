
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  NeModalBody
} from '@ne/ne-vue2-lib';

@Component({
  name: 'ModalBody',
  components: {
    NeModalBody
  }
})
export default class ModalBody extends Vue {
  @Prop({ default: null }) type: string
}
