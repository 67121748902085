export interface SliderBox {
  alt: string;
  src: string;
  targetBlank: string | null;
  title: string;
  title2: string;
  url: string;
  urlTitle: string;
}

export interface SliderSlideImage {
  alt: string;
  src: string;
  title: string | null;
}

export enum SliderSlideLayout {
  NO_IMAGE = 1,
  CENTER,
  CONTENT_LEFT,
  CONTENT_RIGHT
}

export type SliderSlideLayoutName =
  SliderSlideLayout.NO_IMAGE |
  SliderSlideLayout.CENTER |
  SliderSlideLayout.CONTENT_LEFT |
  SliderSlideLayout.CONTENT_RIGHT;

export interface SliderSlide {
  active: boolean | null;
  aliasUrl: string | null;
  btnStyle: string | null;
  buttonTitle: string | null;
  image: SliderSlideImage;
  imageBackground: string;
  layout: SliderSlideLayoutName;
  logo: string | null;
  shortText: string | null;
  tags: any
  targetUrl:string | null;
  title: string | null;
  url: string | null;
  urlTitle: string | null;
}

export interface Slider {
  box: SliderBox[];
  slides: any;
  uuid: string;
  widget: 'FrontSliderEditForm';
}

export enum staticSliderLabels {
  GREEN = 'green',
  ORANGE = 'orange',
  BIG = 'big',
  SECONDARY = 'secondary'
}
