import { Exercise } from '@/models';
import { RouteName } from '@/router/models';
import { CLogLevel } from '@/models/gtm';
import { GTLinkTypes } from '@/config';
import { TranslateResult } from 'vue-i18n';

export const getExercisePropertyValue = (cachedExercises: Exercise[], id: number, property: string): string => {
  const cachedExercise = cachedExercises.find(el => el.id === id) as any;
  return cachedExercise[property] ?? null;
};

export const cLogLevel = (tabName: RouteName | string): RouteName => {
  const cLogLevelMap: any = {
    [RouteName.TEST_DATABASE]: CLogLevel.METHOD,
    [RouteName.TEST_COMPOSER]: CLogLevel.CONTENT,
    [RouteName.EXERCISE_DATABASE]: CLogLevel.METHOD,
    [RouteName.EXERCISE_COMPOSER]: CLogLevel.CONTENT
  };
  return cLogLevelMap[tabName];
};

export const getPdfSubType = (type: GTLinkTypes): TranslateResult => {
  const typeMap = [
    'Test (bez widocznej punktacji)',
    'Test (z widoczną punktacją)',
    'Klucz odpowiedzi',
    'Test eko',
    'Paczka ZIP',
    'Załącznik'
  ];
  return typeMap[type];
};
