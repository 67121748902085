
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  NeSpinner,
  NeIcon,
  NeAppWrapper
} from '@ne/ne-vue2-lib';
import { LoadingStatus, LoadingStatusName } from '@/shared/models/loading';

@Component({
  name: 'PageContent',
  components: {
    NeSpinner,
    NeIcon,
    NeAppWrapper
  }
})
export default class PageContent extends Vue {
  @Prop() loadingStatus: LoadingStatusName;
  @Prop({ default: '' }) loaderText: string;
  @Prop({ default: true }) showError: boolean;

  get isLoading (): boolean {
    return this.loadingStatus === LoadingStatus.INCOMPLETE;
  }

  get computedLoaderText (): string {
    return this.loaderText || this.$tc('COMMON.loading');
  }

  get isError (): boolean {
    return this.loadingStatus === LoadingStatus.ERROR && this.showError;
  }
}
