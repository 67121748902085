
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  NeTooltip
} from '@ne/ne-vue2-lib';
import { TranslateResult } from 'vue-i18n';

@Component({
  name: 'Tooltip',
  components: {
    NeTooltip
  }
})
export default class Tooltip extends Vue {
  @Prop({ default: '' }) text: TranslateResult;
  @Prop({ default: '' }) link: string;
  @Prop({ default: false }) bottom: boolean;
  @Prop({ default: false }) clickable: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) left: boolean;
  @Prop({ default: false }) right: boolean;
  @Prop({ default: true }) noWrap: boolean;
  @Prop({ default: true }) top: boolean;
}
