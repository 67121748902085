
import Vue, { PropOptions } from 'vue';
import { NeWidgetCascadeTiles } from '@ne/ne-vue2-lib/';
import { CascadeTile, CascadeTiles, Widget } from '@ne/ne-mul-interface-api';

const widgetCascadeTilesResponsiveOptionsMock = [
  {
    breakpoint: 320,
    leadLineClampNumber: 6
  },
  {
    breakpoint: 576,
    leadLineClampNumber: 5
  }
];

export default Vue.extend({
  name: 'WidgetCascadeTiles',
  components: {
    NeWidgetCascadeTiles
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<CascadeTiles>>
  },
  data () {
    return {
      primaryTiles: this.data.attributes.primaryTiles,
      secondaryTiles: this.data.attributes.secondaryTiles,
      lineColor: 'grey',
      lineIconColor: 'primary',
      responsiveOptions: widgetCascadeTilesResponsiveOptionsMock
    };
  },
  computed: {
    secondaryNestedTilesList (): CascadeTile[] {
      const secondaryNestedTilesList: CascadeTile[] = [];
      if (this.primaryTiles !== null) {
        this.primaryTiles.forEach((el) => {
          if (el.secondaryNestedTiles !== null) {
            el.secondaryNestedTiles?.forEach((item) => {
              secondaryNestedTilesList.push(item);
            });
          }
        });
      }
      return secondaryNestedTilesList;
    }
  }
});
