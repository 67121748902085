
import Vue, { PropOptions } from 'vue';
import { NeWidgetMultimedia } from '@ne/ne-vue2-lib';
import { Multimedia } from '@/models/widgets/multimedia';

export default Vue.extend({
  name: 'WidgetMultimedia',
  components: {
    NeWidgetMultimedia
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Multimedia>
  },
  computed: {
    items () {
      return this.data.boxs.map(({ type, source, title, poster }) => {
        const widgetItem = {
          type,
          source,
          title,
          poster: poster ? poster.url : null
        };
        return widgetItem;
      });
    }
  }
});
