import { AnswersType, ExerciseCreateData, ExerciseFieldOption, Source } from '@/models/exercises';
import { answersTypes } from '@/shared/data/exercises';
import { ExercisePreview } from '@/models';

export const exerciseLevels = (exercise: ExerciseCreateData): ExerciseFieldOption[] => {
  return exercise.level ? exercise.level.options || [] : [];
};

export const exerciseLevel = (exerciseFieldOptions: ExerciseFieldOption[], exercise: ExerciseCreateData): string => {
  let label = '';
  if (exercise.level) {
    const value = exercise.level.value;
    const option = exerciseFieldOptions.find(option => value === option.id);
    if (option) label = option.label || '';
  }
  return label;
};

export const filtersArrFlat = (arr: any[]): any[] => arr.map((val) => [...[...val.children ?? []], { id: val.id, name: val.name }]).flat(1);

export const filterName = (options: any[], id: number): any => {
  const filter = filtersArrFlat(options).find((el) => el.id === id);
  return filter.name ?? null;
};

export const sectionName = (options: ExerciseFieldOption[], id: number): string | null => {
  const sectionArrFlat = options.map(option => {
    if (option.children) { return [...option.children]; }
  }).flat(1);

  return sectionArrFlat.find(el => { if (el?.id && el.id === id) return el.name; })?.name ?? null;
};

export const answerTypeText = (id: AnswersType | null): string | null => {
  const answerType = answersTypes.find(answer => answer.id === id);
  return answerType?.text ?? null;
};

export const isExerciseOldType = (answerType: AnswersType | null): boolean | null => {
  return answerType ? [
    AnswersType.OPEN_CLOSED,
    AnswersType.OPEN,
    AnswersType.CLOSED,
    AnswersType.FLASHCARD
  ].includes(answerType) : null;
};

export const isTeacherVariantTypeTheSame = (exercise: ExercisePreview, variantIndex: number): boolean => {
  if (exercise.variants[0].author === Source.NOWA_ERA && exercise.variants[variantIndex].author === Source.MY) {
    if (exercise.answersType === AnswersType.BUNDLES) {
      return exercise.variants[0].bundles?.length === exercise.variants[variantIndex].bundles?.length;
    }
    return exercise.variants[0].answers?.length === exercise.variants[variantIndex].answers?.length;
  }
  return true;
};
