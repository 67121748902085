
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { FileIcon } from '@/shared/components/FileIcon';
import {
  NeIconButton,
  NeButton
} from '@ne/ne-vue2-lib';

@Component({
  name: 'FileUploader',
  components: {
    NeIconButton,
    NeButton,
    FileIcon
  }
})
export default class FileUploader extends Vue {
  @Prop({ default: '' }) initialFile: string;
  @Prop({ default: false }) showFile: boolean;
  @Prop() filename: string;
  @Prop() acceptedExtensions?: string[];
  @Prop() maxSize: number;

  file: File | null = null;
  isLoaded = false;

  get extension (): string {
    return this.filename.split('.').slice(-1)[0];
  }

  get accept (): string {
    return this.acceptedExtensions ? this.acceptedExtensions.map(ext => `.${ext}`).join(',') : '*';
  }

  mounted (): void {
    this.isLoaded = this.initialFile !== '';
  }

  onFileChange (e: any): void {
    const file = e.target.files[0];
    (this.$refs.fileInput as HTMLInputElement).value = '';
    const size = file.size / 1024 / 1024;
    if (!this.maxSize || size <= this.maxSize) {
      this.file = file;
      this.isLoaded = true;
      this.upload();
    } else {
      alert(this.$t('EXERCISE_COMPOSER.max_file_size_error', { maxSize: this.maxSize }));
    }
  }

  removeFile (): void {
    this.isLoaded = false;
    this.file = null;
    this.remove();
  }

  @Emit() upload (): File | null {
    return this.file;
  }

  @Emit() remove (): void {}
}
