
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  NeIcon,
  NeModalHeader
} from '@ne/ne-vue2-lib';

@Component({
  name: 'ModalHeader',
  components: {
    NeModalHeader,
    NeIcon
  }
})
export default class ModalHeader extends Vue {
  @Prop({ default: null }) title: string | null;

  @Emit()
  closeClick (): void {}
}
