
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { getMaxPrintHeight } from '@/helpers';
import { Active, ROLES } from '@/config';
import { Exercise, TestModel, TestTypeIdEnum } from '@/models';
import { namespace } from 'vuex-class';
import {
  DISPLAY_RECREATED,
  EXERCISES_REMOVE,
  GET_SELECTED_VARIANT,
  SET_EXERCISE_TO_PREVIEW,
  SET_SELECTED_VARIANTS,
  USER_NOTIFY
} from '@/store/list';
import { ActionButton, DeleteExerciseModal, ExerciseInTestModal, Tooltip } from '@/shared/components';
import { LayoutModel, MenuModel } from '@/store/layout-store/types';
import { NeIcon, NeSpinner, NeTile } from '@ne/ne-vue2-lib';
import { RouteName } from '@/router/models';
import { ExerciseToAdd, Source } from '@/models/exercises';
import { isExerciseOldType, isTeacherVariantTypeTheSame } from '@/helpers/exercise';
import { isRedactorGroup, isTeacherGroup } from '@/helpers/roles';
import Scale from '@/shared/components/Scale/Scale.vue';

const AppStore = namespace('AppStore');
const TestStore = namespace('TestStore');
const ExerciseStore = namespace('ExerciseStore');
const LayoutStore = namespace('LayoutStore');
@Component({
  name: 'ExerciseTile',
  components: {
    Scale,
    DeleteExerciseModal,
    ExerciseInTestModal,
    Tooltip,
    NeSpinner,
    ActionButton,
    NeIcon,
    NeTile
  }
})
export default class ExerciseTile extends Vue {
  @Prop() currentExerciseId: number;
  @Prop() exercise!: Exercise;
  @Prop() isCopying: boolean;
  @Prop() isNewStructureBtnShown!: boolean;
  @Prop() scaleFactor: number;
  @Prop() userRole: ROLES[];
  @Prop({ default: false }) isTestComposerTab: boolean;

  exerciseMaxPrintHeight = 0;
  exerciseToDeleteId = 0;
  height = 100;
  isInTest = false;
  isTeacherVariantTypeTheSame = isTeacherVariantTypeTheSame;
  showDeleteExerciseModal = false;
  showExerciseInTestModal = false;

  @AppStore.State('categoryKey') categoryKey: string;
  @AppStore.State('lastStep') lastStep: number;
  @ExerciseStore.Getter(GET_SELECTED_VARIANT) getSelectedVariant!: (id:number) => number;
  @LayoutStore.State('menu') menu: MenuModel;
  @LayoutStore.State('layout') layout: LayoutModel;
  @TestStore.State('currentTest') currentTest: TestModel;

  get isEditDisabled () {
    return this.userRole.includes(ROLES.TEACHER) && this.exercise.author === Source.CKE;
  }

  get showOndorio (): boolean | null {
    return this.layout.user && this.layout.user.isOndorio;
  }

  get isCurrentExerciseCopying (): boolean {
    if (this.currentExerciseId && this.currentExerciseId === this.exercise.id) {
      return this.isCopying;
    }
    return false;
  }

  get exerciseVariantAnswers (): string | null {
    const answers = this.exercise.variants[this.variantIndex].answers;
    return answers ? JSON.stringify(answers) : null;
  }

  get isEditEnabled (): boolean {
    if (this.userRole.includes(ROLES.ADMIN) || this.userRole.includes(ROLES.PUBLISHER) || this.userRole.includes(ROLES.TEACHER)) {
      return true;
    } else {
      return this.exercise.active !== Active.PUBLISHED;
    }
  }

  get firstGroupExercises (): any[] {
    if (this.$route.name !== RouteName.EXERCISE_DATABASE && this.currentTest?.variants?.length) {
      return this.currentTest.variants[0].pages.reduce((acc: any, val: any) => acc.concat(val), []);
    }
    return [];
  }

  get firstGroupExerciseVariant (): any {
    return this.firstGroupExercises.find(exercise => exercise.id === this.exercise.id);
  }

  get variantInTest (): number | null {
    return this.firstGroupExerciseVariant ? this.firstGroupExerciseVariant.variant : null;
  }

  get inTest (): boolean {
    if (this.$route.name === RouteName.EXERCISE_DATABASE) {
      return false;
    }
    return !!this.firstGroupExerciseVariant && !!this.exercise.isPublic && this.exercise.isPublic;
  }

  get variantIndex (): number {
    return this.getSelectedVariant(this.exercise.id);
  }

  get variantId (): number {
    return this.exercise.variants[this.variantIndex].id;
  }

  get canDelete (): boolean {
    if (this.$route.name !== RouteName.TEST_COMPOSER) {
      if (this.userRole.includes(ROLES.ADMIN)) return true;
      if (isRedactorGroup(this.userRole)) return this.exercise.active !== Active.PUBLISHED;
      if (isTeacherGroup(this.userRole)) return this.exercise.isOwner ?? false;
    }
    return false;
  }

  get shouldDisplayAddBtn (): boolean {
    return this.exercise.active === Active.PUBLISHED;
  }

  get isCopyBtnShown (): boolean {
    return this.isNewStructureBtnShown && !this.exercise.copied && this.canDelete && !this.isCurrentExerciseCopying;
  }

  get isCopiedBtnShown (): boolean {
    return this.isNewStructureBtnShown && this.exercise.copied && this.canDelete;
  }

  get exerciseToAddEdit (): ExerciseToAdd {
    return {
      exercise: {
        active: this.exercise.active,
        attachments: this.exercise.attachments,
        chapter: this.exercise.chapter ?? null,
        id: this.exercise.id,
        level: this.exercise.level,
        printHeight: this.exercise.variants[this.variantIndex].printHeight,
        score: this.exercise.score,
        time: this.exercise.time,
        task: this.exercise.variants[this.variantIndex].content,
        section: this.exercise.section ?? null,
        title: this.exercise.title,
        variantId: this.exercise.variants[this.variantIndex].id,
        variantNumber: this.variantIndex,
        variants: this.exercise.variants
      }
    };
  }

  get isExerciseOldType () {
    return isExerciseOldType(this.exercise.answersType);
  }

  get isCommentIconVisible (): boolean {
    return this.userRole.includes(ROLES.TEACHER) && (this.exercise.variants[0].author === Source.NOWA_ERA || this.exercise.variants[0].author === Source.CKE);
  }

  get addToTestLabel (): string {
    if (this.isTestComposerTab) {
      return this.currentTest.generatorTestTypeId === TestTypeIdEnum.WORK_CARD ? this.$tc('COMMON.btn_add_to_work_card') : this.$tc('COMMON.btn_add_to_test');
    }
    return this.$tc('COMMON.btn_add_to_test');
  }

  @Watch('exercise', { deep: true })
  exerciseChangeHandler (newExercise: any) {
    this.calculateWrapper(newExercise);
  }

  created (): void {
    if (this.variantInTest) {
      const variantIndex = this.exercise.variants.findIndex(variant => variant.id === this.variantInTest);
      this.selectVariant({ exerciseId: this.exercise.id, variantIndex: variantIndex });
    }
  }

  mounted (): void {
    this.calculateWrapper(this.exercise);
  }

  @AppStore.Mutation(DISPLAY_RECREATED) recreatedGroups: any;
  @AppStore.Action(USER_NOTIFY) notify: any;
  @ExerciseStore.Mutation(SET_SELECTED_VARIANTS) selectVariant!: any;
  @ExerciseStore.Action(SET_EXERCISE_TO_PREVIEW) previewExercise: any;
  @TestStore.Action(EXERCISES_REMOVE) removeExercise: any;

  @Emit() openCopyModal () {
    return this.exercise;
  }

  @Emit() exerciseDeleted () {}

  @Emit() exercisePreviewOpen () {
    return this.exercise.variants[this.variantIndex].id;
  }

  @Emit() onExerciseEdit () {}

  updateHeight (ev: CustomEvent) {
    this.height = ev.detail.height;
  }

  setExerciseToPreview () {
    this.previewExercise(this.exercise.id);
    this.exercisePreviewOpen();
  }

  checkEdit (): void {
    if (this.exercise.exerciseInTests.length > 0) {
      this.showExerciseInTestModal = true;
    } else {
      this.editExercise();
    }
  }

  editExercise (): void {
    this.onEditModalClose();
    if (this.$route.name !== RouteName.EXERCISE_DATABASE) {
      sessionStorage.setItem('testInEdition', JSON.stringify(this.currentTest));
    }
    this.$router.push({
      name: RouteName.EXERCISE_EDITOR,
      params: {
        exerciseId: this.exercise.id.toString() || '',
        variantIndex: (this.variantIndex + 1).toString()
      }
    });
    this.onExerciseEdit();
    this.editExerciseEvent();
  }

  changeVariant (index: number): void {
    this.selectVariant({ exerciseId: this.exercise.id, variantIndex: index });
  }

  @Emit()
  editExerciseEvent (): ExerciseToAdd {
    return this.exerciseToAddEdit;
  }

  @Emit()
  addToTest (): ExerciseToAdd {
    this.isInTest = true;
    return this.exerciseToAddEdit;
  }

  removeFromTest (): void {
    this.isInTest = false;
    this.removeExercise(this.exercise, this.currentTest.earlySchoolEducation);
    this.notify(this.$tc('NOTIFICATIONS.exercise_was_detached_from_test'));
    if (this.lastStep === 2) {
      this.recreatedGroups();
    }
  }

  calculateWrapper (exercise: any): void {
    const maxArray = exercise.variants.map((o: any) => parseInt(o.printHeight, 10));
    this.exerciseMaxPrintHeight = getMaxPrintHeight(maxArray);
  }

  onEditModalClose (): void {
    this.showExerciseInTestModal = false;
  }

  onDeleteModalClose (): void {
    this.showDeleteExerciseModal = false;
  }

  askDeleteModal (exerciseId: number): void {
    this.exerciseToDeleteId = exerciseId;
    this.showDeleteExerciseModal = !this.showDeleteExerciseModal;
  }
}
