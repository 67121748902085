
import Vue, { PropOptions } from 'vue';
import { NeWidgetGraphics } from '@ne/ne-vue2-lib';
import { Graphic, Widget } from '@ne/ne-mul-interface-api';

export default Vue.extend({
  name: 'WidgetGraphic',
  components: {
    NeWidgetGraphics
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<Graphic>>
  }
});
