import { Vue, Component } from 'vue-property-decorator';

@Component
export default class FixUrlMixin extends Vue {
  getTabRoute (tabUrl: string): string {
    const basePath = 'generator-zadan';
    const basePathLength: number = basePath.length;
    const urlRootPosition: number = tabUrl.search(basePath) + basePathLength;
    return tabUrl.substring(urlRootPosition);
  }
}
