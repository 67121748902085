
import Vue, { PropOptions } from 'vue';
import { NeWidgetGallery } from '@ne/ne-vue2-lib';
import { Gallery, Widget } from '@ne/ne-mul-interface-api';

const widgetGalleryResponsiveOptionsMock = [
  {
    breakpoint: 320,
    arrowIconSize: 30,
    cancelIconSize: 20
  },
  {
    breakpoint: 768,
    arrowIconSize: 40,
    cancelIconSize: 30
  },
  {
    breakpoint: 1200,
    arrowIconSize: 50,
    cancelIconSize: 30
  }
];

export default Vue.extend({
  name: 'WidgetGallery',
  components: {
    NeWidgetGallery
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<Gallery>>
  },
  data () {
    return {
      icon: 'arrow-bottom',
      responsiveOptions: widgetGalleryResponsiveOptionsMock
    };
  }
});
