import { GTLinkTypes } from '@/config';
import { TestTypeIdEnum } from '@/models';

export interface PdfLink {
  type: number;
  order: number;
  link: string;
  icon: Icon;
  isDownloading: boolean;
}

interface Icon {
  iconType: string;
  name: string;
  fileName: string;
}

export const getPdfLinks = (links: string[], userHash: string, apiUrl: string, testType: TestTypeIdEnum): PdfLink[] => {
  const pdfLinks = [];
  for (const linkType in GTLinkTypes) {
    if (!isNaN(Number(linkType))) {
      const link = {
        type: Number(linkType),
        link: `${apiUrl}${links[linkType]},${userHash}`,
        icon: getLinkIcon(Number(linkType), testType),
        order: getOrder(Number(linkType)),
        isDownloading: false
      };
      pdfLinks.push(link);
    }
  }
  return pdfLinks;
};

export const getLinkIcon = (type: GTLinkTypes, testType: TestTypeIdEnum) => {
  const isWorkCard = testType === TestTypeIdEnum.WORK_CARD;

  switch (type) {
  case GTLinkTypes.NO_SCORING:
    return { name: 'TEST_PREVIEW.download.test_no_scoring', iconType: 'pdf', fileName: 'Test (bez widocznej punktacji).pdf' };
  case GTLinkTypes.NORMAL:
    return { name: isWorkCard ? 'TEST_PREVIEW.download.work_card' : 'TEST_PREVIEW.download.test', iconType: 'pdf', fileName: isWorkCard ? 'Karta pracy.pdf' : 'Test (z widoczną punktacją).pdf' };
  case GTLinkTypes.ANSWER_KEY:
    return { name: 'TEST_PREVIEW.download.answer_key', iconType: 'pdf', fileName: 'Klucz odpowiedzi.pdf' };
  case GTLinkTypes.ECO:
    return { name: 'TEST_PREVIEW.download.eco', iconType: 'pdf_eco', fileName: 'Test – ekowydruk.pdf' };
  case GTLinkTypes.ZIP:
    return { name: 'TEST_PREVIEW.download.zip', iconType: 'zip', fileName: 'Załączniki.zip' };
  case GTLinkTypes.ANSWER_CARD:
    return { name: 'TEST_PREVIEW.download.answer_card', iconType: 'pdf_ondorio', fileName: 'Karta odpowiedzi.pdf' };
  case GTLinkTypes.SPE:
    return { name: isWorkCard ? 'TEST_PREVIEW.download.spe_word_card' : 'TEST_PREVIEW.download.spe', iconType: 'pdf', fileName: isWorkCard ? 'Karta pracy – SPE.pdf' : 'Test – SPE.pdf' };
  default:
    return { name: '', iconType: '', fileName: '' };
  }
};

export const getOrder = (type: GTLinkTypes) => {
  switch (type) {
  case GTLinkTypes.NO_SCORING:
    return 2;
  case GTLinkTypes.NORMAL:
    return 1;
  case GTLinkTypes.ANSWER_KEY:
    return 5;
  case GTLinkTypes.ECO:
    return 0;
  case GTLinkTypes.ZIP:
    return 3;
  case GTLinkTypes.ANSWER_CARD:
    return 6;
  case GTLinkTypes.SPE:
    return 4;
  default:
    return -1;
  }
};
