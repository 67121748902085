export enum EventName {
  ADD_TO_SET = 'add_to_set',
  CREATE = 'create',
  DOWNLOAD_RESOURCE = 'download_resource',
  EDIT = 'edit',
  SHOW_PREVIEW = 'show_preview',
  USE_FILTER = 'use_filter',
  USE_FILTER_BOOKYEAR = 'use_filter_bookyear'
}

export enum CLogLevel {
  CONTENT = 'content',
  HP = 'hp',
  METHOD = 'method'
}

export enum ContentType {
  EXERCISE = 'zadanie',
  TEST = 'test'
}
