
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  NeCheckbox
} from '@ne/ne-vue2-lib';

@Component({
  name: 'ModalLockHandler',
  components: {
    NeCheckbox
  }
})
export default class ModalLockHandler extends Vue {
  @Prop() cookieName: string;
  @Prop({ default: Infinity }) unlockTime: string | number | Date | undefined;

  isLockChecked: boolean = false;

  toggleCookie (): void {
    if (this.isLockChecked) {
      this.$cookies.set(this.cookieName, 'true', this.unlockTime);
    } else {
      this.$cookies.remove(this.cookieName);
    }
  }
}
