
import Vue, { PropOptions } from 'vue';
import { NeWidgetAdvancedText } from '@ne/ne-vue2-lib';
import { AdvancedText } from '@/models/widgets/advanced-text';

export default Vue.extend({
  name: 'WidgetAdvancedText',
  components: {
    NeWidgetAdvancedText
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<AdvancedText>
  }
});
