
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NeTextField } from '@ne/ne-vue2-lib';

@Component({
  name: 'TextField',
  components: {
    NeTextField
  }
})
export default class TextField extends Vue {
  @Prop() value!: string | number;
  @Prop({ default: 'text' }) type!: string;
  @Prop({ default: 0 }) min!: number;
  @Prop() max?: number;
  @Prop({ default: 1 }) step!: number;
  @Prop() label?: string;
  @Prop() width?: number;

  @Emit()
  input (value: string): string {
    return value;
  }

  @Emit() change () {}
}
