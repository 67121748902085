import { Slider } from './widgets/slider';
import { FilesList } from './widgets/files-list';
import { CustomWidget } from './widgets/custom';
import { Rotator } from './widgets/rotator';
import { Multimedia } from './widgets/multimedia';
import { ArticleBlock } from './widgets/article-block';
import { ESolutions } from './widgets/e-solutions';
import { AdvancedText } from './widgets/advanced-text';
import { Illustration } from './widgets/illustration';
import { DynamicBannersResponse } from './widgets/dynamic-banners';
import { GeneratorStats } from './widgets/generator-stats';

export enum WidgetType {
  SLIDER = 'FrontSliderEditForm',
  FILES_LIST = 'ListsFileEditForm',
  ROTATOR = 'RotatorEditForm',
  CALENDARIUM = 'CalendariumEditForm',
  MULTIMEDIA = 'MultimediaEditForm',
  ARTICLE_BLOCK = 'ArticlesBlockHPEditForm',
  E_SOLUTIONS = 'EsolutionsEditForm',
  ADVANCED_TEXT = 'TextEditForm',
  ILLUSTRATION = 'ImageEditForm',
  DYNAMIC_BANNERS = 'BannerEditForm',
  TEACHER_RESOURCES = 'NewFilesEditForm',
  GENERATOR_STATS = 'GeneratorStatsEditForm'
}

export enum MulWidgetType {
  STATIC_BANNERS = 'staticBanners',
  FAQ = 'faq',
  FORM = 'form',
  GALLERY = 'gallery',
  GRAPHIC = 'graphic',
  ARTICLE_INDEX = 'articleIndex',
  CASCADE_TILES = 'cascadeTiles',
  REVERSIBLE_TILES = 'reversibleTiles',
  CLICKABLE_INFO_TILE = 'clickableInfoTiles',
  TEXT_GRAPHIC_BUTTONS = 'textGraphicButtons',
  VIDEO = 'video',
  ADVANCED_TEXT = 'advancedText',
  ATTACHMENTS = 'attachments'
}

export interface OneColumnPage {
  id: number;
  name: string;
  sections: { content: ApiWidget[] };
}

export type ApiWidget =
  Slider |
  FilesList |
  CustomWidget |
  Rotator |
  Multimedia |
  ArticleBlock |
  ESolutions |
  AdvancedText |
  Illustration |
  DynamicBannersResponse |
  GeneratorStats;
