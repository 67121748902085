
import Vue, { PropOptions } from 'vue';
import { NeWidgetBanners } from '@ne/ne-vue2-lib';
import { DynamicBannersResponse, BannersResponse, BannersItem, BannersItemImagesUrls } from '@/models/widgets/dynamic-banners';

export default Vue.extend({
  name: 'WidgetDynamicBanners',
  components: {
    NeWidgetBanners
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<DynamicBannersResponse>
  },
  methods: {
    getBannerNumber (banners: BannersResponse): number {
      return parseInt(banners.style[0]);
    },
    getBannerHeight (banners: BannersResponse): number {
      return parseInt(banners.style.split('_')[2]);
    },
    getBanners (banners: BannersResponse): BannersItem[] {
      const result = [] as BannersItem[];
      const bannerHeight = this.getBannerHeight(banners);
      banners.images.forEach((image) => {
        const banner = {
          title: image.title,
          href: image.url,
          newWindow: image.targetBlank,
          alt: image.alt,
          src: this.getImages(image.src, bannerHeight)
        } as BannersItem;
        result.push(banner);
      });
      return result;
    },
    getImages (sources: any, height: number): BannersItemImagesUrls {
      return {
        xs: sources[`4_255_${height}`],
        sm: sources[`3_350_${height}`],
        md: sources[`2_540_${height}`],
        lg: sources[`1_1110_${height}`]
      };
    }
  }
});
