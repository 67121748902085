
import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DELETE_EXERCISE } from '@/store/list';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from '@/shared/components/Modal';

const ExerciseStore = namespace('ExerciseStore');
@Component({
  name: 'DeleteExerciseModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton
  }
})
export default class DeleteExerciseModal extends Vue {
  @PropSync('active') value: boolean;
  @Prop() exerciseId: number;
  @Prop() isOndorioInfoVisible: boolean;

  @ExerciseStore.Action(DELETE_EXERCISE) deleteExerciseStore: any;

  @Emit() onDeleteModalClose () {}
  @Emit() exerciseDeleted () {}

  close () {
    this.value = false;
  }

  async deleteExercise () {
    await this.deleteExerciseStore(this.exerciseId);
    this.exerciseDeleted();
    this.onDeleteModalClose();
  }
}
