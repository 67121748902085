
import Vue from 'vue';
import type { PropOptions } from 'vue';
import { NeWidgetVideoPlayer } from '@ne/ne-vue2-lib';
import { Video, Widget } from '@ne/ne-mul-interface-api';

export default Vue.extend({
  name: 'WidgetVideo',
  components: {
    NeWidgetVideoPlayer
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<Video>>
  },
  data () {
    return {
      lineColor: 'grey',
      lineIconColor: 'primary',
      lineIcon: 'arrow-bottom',
      tileIconColor: 'primary'
    };
  }
});
