
import Vue, { PropOptions } from 'vue';
import { Location } from 'vue-router';
import { NeWidgetArticleIndex } from '@ne/ne-vue2-lib';
import { ArticleIndex, Widget } from '@ne/ne-mul-interface-api';

export default Vue.extend({
  name: 'WidgetArticleIndex',
  components: {
    NeWidgetArticleIndex
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<ArticleIndex>>
  },
  data () {
    return {
      icon: 'arrow-bottom',
      buttonQuantity: +this.data.attributes.buttonQuantity,
      columnNumber: +this.data.attributes.columnQuantity,
      buttonTitleSeo: this.data.attributes.buttonTitleSeo,
      lineColor: 'grey',
      lineIconColor: 'primary',
      roundedSmall: true,
      tileBgColor: 'white'
    };
  },
  computed: {
    articlesTiles (): Object[] {
      return (this.data.attributes as ArticleIndex).articles.map((item) => {
        return {
          ...item,
          source: this.getArticleLink(item.source)
        };
      });
    }
  },
  methods: {
    getArticleLink (url: string): Location {
      const path = url.includes('api-dlanauczyciela')
        ? url.split('contents')[1]
        : url.replace('/api', '');
      return {
        path
      };
    }
  }
});
