
import Vue, { PropOptions } from 'vue';
import { NeWidgetArticleBlock } from '@ne/ne-vue2-lib';
import { ArticleBlock, Tile } from '@/models/widgets/article-block';

export default Vue.extend({
  name: 'WidgetArticleBlock',
  components: {
    NeWidgetArticleBlock
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<ArticleBlock>
  },
  computed: {
    articleBlock (): ArticleBlock {
      const articleBlock: ArticleBlock = JSON.parse(JSON.stringify(this.data));
      articleBlock.tiles.forEach((tile: Tile) => {
        const articleKey = tile.uri.split('/').join(',');
        tile.to = { name: 'article-page', params: { articleKey } };
      });
      return articleBlock;
    }
  }
});
