
import Vue, { PropOptions } from 'vue';
import { NeWidgetFaq } from '@ne/ne-vue2-lib';
import { Faq, FaqTile, Widget } from '@ne/ne-mul-interface-api';

export default Vue.extend({
  name: 'WidgetFaq',
  components: {
    NeWidgetFaq
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<Faq>>
  },
  data () {
    return {
      alignLeft: true,
      icon: 'arrow-bottom',
      bordered: true,
      roundedSmall: true,
      placeholder: 'Napisz czego szukasz',
      inputRightIconColor: 'green',
      inputRightIconSize: 20,
      inputFontSize: 17,
      inputFontSizeMobile: 14,
      searchMaxWidth: 695,
      inputHeight: 55,
      inputBgColor: 'white',
      categories: this.data.attributes.primaryTiles,
      initialCategoryIndex: 0,
      categoryTextColor: 'default',
      activeCategoryTextColor: 'white',
      categoryHoverColor: 'white',
      categoryBgColor: 'white',
      borderColor: 'grey',
      borderRadius: 5,
      withLine: true,
      lineWithDecor: true,
      initialQuestionIndex: -1,
      paginationColor: 'green',
      activePageTextColor: 'white',
      dropdownItemHoverBgColor: 'grey-lighten-4',
      dropdownBgColor: 'white',
      noMatchingItemsLabel: 'Brak dopasowań szukanej frazy.',
      noSearchResultsText: 'Brak wyników wyszukiwania.',
      resultsPerPage: 2,
      animationDuration: 250,
      evenItemsBgColor: 'grey-lighten-4',
      questions: this.data.attributes.secondaryTiles,
      scrollValue: 120
    };
  },
  computed: {
    secondaryNestedTilesList (): FaqTile[] {
      const secondaryNestedTilesList: FaqTile[] = [];
      if (this.categories !== null) {
        this.categories.forEach((el) => {
          if (el.secondaryNestedTiles !== null) {
            el.secondaryNestedTiles?.forEach((item) => {
              secondaryNestedTilesList.push(item);
            });
          }
        });
      }
      return secondaryNestedTilesList;
    }
  }
});
