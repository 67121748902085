
import Vue, { PropOptions } from 'vue';
import { NeWidgetRotator } from '@ne/ne-vue2-lib';
import { Rotator } from '@/models/widgets/rotator';

export default Vue.extend({
  name: 'WidgetRotator',
  components: {
    NeWidgetRotator
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Rotator>
  },
  data () {
    return {
      responsiveOptions: [
        {
          breakpoint: 360,
          itemsToShow: 1,
          itemsToScroll: 1
        },
        {
          breakpoint: 576,
          itemsToShow: 2,
          itemsToScroll: 1
        },
        {
          breakpoint: 768,
          itemsToShow: 3,
          itemsToScroll: 1
        },
        {
          breakpoint: 992,
          itemsToShow: 4,
          itemsToScroll: 1
        },
        {
          breakpoint: 1200,
          itemsToShow: 5,
          itemsToScroll: 1
        }
      ]
    };
  }
});
