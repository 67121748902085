export const debounce: (...params: any[]) => any = function (func: (...params: any[]) => any, wait:number, immediate: boolean) {
  let timeout: NodeJS.Timeout | undefined;
  return function (this: any) {
    const context = this; const args:any = arguments;
    const later = function () {
      timeout = undefined;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
