
import Vue from 'vue';
import type { PropOptions } from 'vue';
import { NeWidgetAdvancedText } from '@ne/ne-vue2-lib';
import { AdvancedText, Widget } from '@ne/ne-mul-interface-api';

export default Vue.extend({
  name: 'WidgetAdvancedText',
  components: {
    NeWidgetAdvancedText
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<AdvancedText>>
  },
  data () {
    return {
      lineColor: 'grey',
      lineIconColor: 'primary',
      lineIcon: 'arrow-bottom'
    };
  }
});
