
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  NeButton
} from '@ne/ne-vue2-lib';

@Component({
  name: 'ModalButton',
  components: {
    NeButton
  }
})
export default class ModalButton extends Vue {
  @Prop({ default: 'primary' }) color: string;
  @Prop({ default: false }) bordered: boolean;
  @Prop({ default: false }) flat: boolean;
  @Prop({ default: '' }) leftIcon: string;
  @Prop({ default: '' }) leftIconColor: string;
  @Prop({ default: false }) outlined: boolean;

  @Emit()
  click (): void {}
}
