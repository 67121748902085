
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NeIcon } from '@ne/ne-vue2-lib';

interface IconData {
  action: string;
  name: string;
  class: string
}

@Component({
  name: 'ActionButton',
  components: {
    NeIcon
  }
})
export default class ActionButton extends Vue {
  @Prop({
    type: String,
    required: true,
    validator: (val: string) => [
      'add',
      'remove',
      'preview',
      'download',
      'copy',
      'edit'
    ].includes(val)
  }) action: 'add' | 'download' | 'remove' | 'preview' | 'copy' | 'edit';

  @Prop() text: string;
  @Prop({ default: false }) withoutApla: boolean;
  @Prop({ default: false }) isDisabled: boolean;

  get neIconMap (): IconData[] {
    return [
      {
        action: 'add',
        name: 'plus-inverse',
        class: this.primaryIconClasses
      },
      {
        action: 'download',
        name: 'download',
        class: ''
      },
      {
        action: 'remove',
        name: 'minus-inverse',
        class: this.removeIconClasses
      },
      {
        action: 'edit',
        name: 'pencil-1',
        class: this.primaryIconClasses
      },
      {
        action: 'preview',
        name: 'eye',
        class: this.primaryIconClasses
      },
      {
        action: 'copy',
        name: 'copy',
        class: this.copyIconClasses
      }
    ];
  }

  get icon (): IconData | undefined {
    return this.neIconMap.find(icon => icon.action === this.action);
  }

  get primaryIconClasses () : string {
    return this.withoutApla ? 'ne--color-primary ne--hover-color-primary-darken-1' : 'ne--bg-color-primary ne--hover-bg-color-primary-darken-1';
  }

  get removeIconClasses () : string {
    return this.withoutApla ? 'ne--color-error' : 'ne--color-error ne--bordered';
  }

  get copyIconClasses () : string {
    return this.withoutApla ? 'ne--color-new-edition ne--hover-color-new-edition-1' : 'ne--bg-color-new-edition ne--hover-bg-color-new-edition-darken-1';
  }

  @Emit() click () {}
}
