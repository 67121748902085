
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GeneratorStats } from '@/models/widgets/generator-stats';
import { thousandSeparator } from '@/filters/filters';
import { isInViewPort } from '@/helpers';
import { debounce } from '@/helpers/debounce';
import {
  SectionHeader
} from '@/shared/components';
import {
  NeSection
} from '@ne/ne-vue2-lib';

@Component({
  name: 'HomeStats',
  components: {
    NeSection,
    SectionHeader
  },
  filters: { thousandSeparator }
})
export default class Home extends Vue {
  @Prop() data: GeneratorStats | null;

  isStatsGrowingFired: boolean = false;
  tmpTestCounter = 0;
  tmpUserTestCounter = 0;
  testCounter = 0;
  userTestCounter = 0;
  testCountInterval: any;
  userCountInterval: any;

  get stats (): any {
    return this.data ? this.data.stats : {};
  }

  async mounted (): Promise<void> {
    window.addEventListener('scroll', debounce(this.handleScroll, 250));
    this.handleScroll();
  }

  handleScroll (): void {
    if (!this.isStatsGrowingFired && this.stats) {
      this.testCounter = this.stats.testPgCounter + this.stats.testCounter;
      this.userTestCounter = this.stats.userTestCounter + this.stats.userTestPgCounter;
      this.tmpTestCounter = this.testCounter - 200;
      this.tmpUserTestCounter = this.userTestCounter - 100;
      if (isInViewPort(this.$refs.section)) {
        this.isStatsGrowingFired = true;
        this.startCounterIntervals();
        window.removeEventListener('scroll', this.handleScroll);
      }
    }
  }

  startCounterIntervals (): void {
    this.testCountInterval = setInterval(this.initTestCounterInterval, 20);
    this.userCountInterval = setInterval(this.initUserCounterInterval, 60);
  }

  initTestCounterInterval (): void {
    if (this.tmpTestCounter < this.testCounter) {
      this.tmpTestCounter = Math.min(this.tmpTestCounter + 2, this.testCounter);
    } else {
      clearInterval(this.testCountInterval);
    }
  }

  initUserCounterInterval (): void {
    if (this.tmpUserTestCounter < this.userTestCounter) {
      this.tmpUserTestCounter = Math.min(this.tmpUserTestCounter + 2, this.userTestCounter);
    } else {
      clearInterval(this.userCountInterval);
    }
  }
}
