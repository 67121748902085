
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Scale',
  components: {}
})
export default class Scale extends Vue {
  @Prop() scaleFactor: number;
  @Prop() height: number;
  @Prop() width?: number;
  @Prop() params: object;

  get boxStyle () {
    if (this.scaleFactor) {
      return {
        height: this.height * this.scaleFactor + 'px',
        width: this.width ? Math.round(this.width * this.scaleFactor) + 'px' : 'auto'
      };
    }
    return null;
  }

  get scaleStyle () {
    if (this.scaleFactor) {
      return {
        transform: 'scale(' + this.scaleFactor + ')',
        'transform-origin': 'left top',
        height: this.height * this.scaleFactor + 'px'
      };
    }
    return null;
  }
}
