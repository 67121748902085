
import { Component, Vue, Prop } from 'vue-property-decorator';
import 'video.js/dist/video-js.css';
import { videoPlayer } from 'vue-video-player';
import { VideoSource } from '@/models';
import { resourcePath } from '@/config';

@Component({
  name: 'VideoPlayer',
  components: { videoPlayer }
})
export default class VideoPlayer extends Vue {
  @Prop({ default: 442 }) height: number;
  @Prop({ default: null }) posterSrc: string | null;
  @Prop() videoSources?: VideoSource[];
  @Prop() source?: VideoSource | null;
  @Prop({ default: false }) autoPlay: boolean;

  get playerOptions (): any {
    return {
      controlBar: {
        durationDisplay: true
      },
      height: this.height.toString(),
      autoplay: this.autoPlay,
      muted: false,
      language: 'pl',
      sources: this.sources,
      poster: this.poster
    };
  }

  get poster (): string {
    return this.posterSrc ? `${resourcePath}${this.posterSrc}` : '';
  }

  get sources (): VideoSource[] {
    return this.source ? [this.source] : this.videoSources || [];
  }
}
