import { Active } from '@/config';

export const exerciseActivityInfo = (active: Active | undefined): string | null => {
  const activeMap = [
    'robocze',
    'opublikowany',
    'gotowy_do_publikacji'
  ];
  return typeof active !== 'undefined' ? activeMap[active] : null;
};

export const testActivityInfo = (isPublic: boolean | number): string => isPublic ? 'opublikowany' : 'robocze';
