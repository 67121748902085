
import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import { NeTextField } from '@ne/ne-vue2-lib';

const minSearchChar = 3;

@Component({
  name: 'SearchBar',
  components: { NeTextField }
})
export default class SearchBar extends Vue {
  @Prop() isExerciseDatabase: boolean;
  @PropSync('searchQuery') value: string;

  @Emit()
  searchTests (searchText: string) { return searchText; }

  @Emit()
  searchExercises (searchText: string) { return searchText; }

  get iconColor (): string {
    return this.isQueryValid ? 'primary' : 'default';
  }

  get isQueryValid (): boolean {
    return this.value?.length >= minSearchChar;
  }

  setSearchQuery (value: string) {
    this.value = value;
  }

  searchHandler () {
    if (this.value.length >= minSearchChar) {
      this.isExerciseDatabase ? this.searchExercises(this.value) : this.searchTests(this.value);
    }
  }
}
