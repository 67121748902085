
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NeFileIcon } from '@ne/ne-vue2-lib';

@Component({
  name: 'file-icon',
  components: {
    NeFileIcon
  }
})
export default class FileIcon extends Vue {
  @Prop() type!: string;
}
