
import Vue, { PropOptions } from 'vue';
import { NeWidgetForms } from '@ne/ne-vue2-lib';
import { Form, Widget } from '@ne/ne-mul-interface-api';

export default Vue.extend({
  name: 'WidgetForm',
  components: {
    NeWidgetForms
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<Form>>
  },
  data () {
    return {
      isSpacerEnabled: this.data.attributes.isSpacerEnabled,
      title: this.data.attributes.title,
      description: this.data.attributes.description,
      privacyPolicy: '',
      buttonTitle: this.data.attributes.buttonTitle,
      backgroundColor: this.data.attributes.backgroundColor,
      schema: this.data.attributes.schema,
      formUrl: this.data.attributes.url,
      isSucces: false,
      buttonColor: this.data.attributes.buttonColor,
      buttonStyle: this.data.attributes.buttonStyle,
      isValidationActive: false,
      fields: this.data.attributes.fields
    };
  }
});
