export enum LoadingStatus {
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
  ERROR = 'error'
}

export type LoadingStatusName =
  'incomplete' |
  'complete' |
  'error';
