
import { Component, Vue, Prop, Emit, PropSync } from 'vue-property-decorator';

interface PageButton {
  value: number;
  label: number | string;
}

@Component({
  name: 'Paginator'
})
export default class Paginator extends Vue {
  @PropSync('page') currentPage!: number;
  @Prop() lastPage!: number;

  get showPagination () {
    return this.lastPage > 1;
  }

  get previousPageButtonClasses (): any[] {
    return [
      { 'gt-paginator__arrow--disabled': this.currentPage === 1 },
      'gt-paginator__arrow',
      'gt-paginator__arrow--preview'
    ];
  }

  get nextPageButtonClasses (): any[] {
    return [
      { 'gt-paginator__arrow--disabled': this.currentPage === this.lastPage },
      'gt-paginator__arrow',
      'gt-paginator__arrow--next'
    ];
  }

  buttonClasses (page: number): any[] {
    return [{ 'gt-paginator__page--current': this.currentPage === page }, 'gt-paginator__page'];
  }

  get pageButtons (): PageButton[] {
    const maxPageButtons = 6;
    let pageButtonsAmount = Math.min(maxPageButtons, this.lastPage);
    if (this.currentPage >= maxPageButtons - 1 && this.currentPage <= this.lastPage - maxPageButtons + 2) {
      pageButtonsAmount++;
    }
    const pageButtons = [];

    for (let i = 0; i < pageButtonsAmount; i++) {
      let pageButton;
      if (i === 0) {
        // first page
        pageButton = {
          value: i + 1,
          label: i + 1
        };
      } else if (i === pageButtonsAmount - 1) {
        // last page
        pageButton = {
          value: this.lastPage,
          label: this.lastPage
        };
      } else if (this.lastPage <= maxPageButtons) {
        // all pages can be displayed
        pageButton = {
          value: i + 1,
          label: i + 1
        };
      } else if (this.currentPage < maxPageButtons - 1) {
        // current page is one of first pages
        if (i !== pageButtonsAmount - 2) {
          // first pages
          pageButton = {
            value: i + 1,
            label: i + 1
          };
        } else {
          // penultimate button
          pageButton = {
            value: Math.round((pageButtonsAmount - 2 + this.lastPage) / 2),
            label: '...'
          };
        }
      } else if (this.currentPage > this.lastPage - maxPageButtons + 2) {
        // current page is one of last pages
        if (i !== 1) {
          const value = this.lastPage - pageButtonsAmount + i + 1;
          // last pages
          pageButton = {
            value,
            label: value
          };
        } else {
          // second button
          const nextValue = this.lastPage - pageButtonsAmount + i + 2;
          pageButton = {
            value: Math.round((1 + nextValue) / 2),
            label: '...'
          };
        }
      } else {
        const middlePageButtonsAmount = pageButtonsAmount - 4;
        const firstMiddlePageButtonValue = this.currentPage - Math.round((middlePageButtonsAmount - 1) / 2);
        if (i === 1) {
          // second button
          pageButton = {
            value: Math.round((1 + firstMiddlePageButtonValue) / 2),
            label: '...'
          };
        } else if (i < pageButtonsAmount - 2) {
          // middle buttons
          const value = firstMiddlePageButtonValue + i - 2;
          pageButton = {
            value,
            label: value
          };
        } else {
          // middle buttons
          const previousValue = firstMiddlePageButtonValue + i - 3;
          pageButton = {
            value: Math.round((previousValue + this.lastPage) / 2),
            label: '...'
          };
        }
      }
      pageButtons.push(pageButton);
    }
    return pageButtons;
  }

  scrollToTop () {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  @Emit()
  change (page: number) {
    this.scrollToTop();
    this.currentPage = page;
  }

  setPage (page: number) {
    if (this.currentPage !== page) this.change(page);
  }

  goToPreviousPage () {
    if (this.currentPage !== 1) this.change(--this.currentPage);
  }

  goToNextPage () {
    if (this.currentPage !== this.lastPage) this.change(++this.currentPage);
  }
}
