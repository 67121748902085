
import Vue, { PropOptions } from 'vue';
import { NeWidgetIllustration } from '@ne/ne-vue2-lib';
import { Illustration } from '@/models/widgets/illustration';

interface SourceSetItem {
  src: string;
  width: string;
}

interface SizeItem {
  media?: string;
  width: string;
}

export default Vue.extend({
  name: 'WidgetIllustration',
  components: {
    NeWidgetIllustration
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Illustration>
  },
  computed: {
    sources (): SourceSetItem[] {
      return [
        {
          src: this.data.src.sm,
          width: '350w'
        },
        {
          src: this.data.src.md,
          width: '540w'
        },
        {
          src: this.data.src.lg,
          width: '1100w'
        }
      ];
    }
  }
});
