
import Vue, { PropOptions } from 'vue';
import { NeWidgetAttachments } from '@ne/ne-vue2-lib/';
import { Attachments, Widget, WidgetFiles } from '@ne/ne-mul-interface-api';

export default Vue.extend({
  name: 'WidgetAttachments',
  components: {
    NeWidgetAttachments
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<Attachments>>
  },
  data () {
    return {
      attributes: this.data.attributes,
      columnQuantity: +this.data.attributes.columnQuantity,
      buttonQuantity: +this.data.attributes.buttonQuantity,
      buttonOpensNewWindow: this.data.attributes.buttonOpensNewWindow,
      color: 'white',
      icon: 'arrow-bottom',
      lineIconColor: 'primary',
      image: (this.data.files as WidgetFiles).image
    };
  }
});
