
import { Prop, Component, Vue } from 'vue-property-decorator';
import {
  NeModalFooter
} from '@ne/ne-vue2-lib';

@Component({
  name: 'ModalFooter',
  components: {
    NeModalFooter
  }
})
export default class ModalFooter extends Vue {
  @Prop({ default: false, type: Boolean }) alignRight: boolean;
  @Prop({ default: false, type: Boolean }) spaceBetween: boolean;

  get classes (): any {
    return {
      'gt-modal-footer--align-right': this.alignRight,
      'gt-modal-footer--space-between': this.spaceBetween
    };
  }
}
