
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NeIcon } from '@ne/ne-vue2-lib';

@Component({
  name: 'ExercisePreviewNavigationButton',
  components: {
    NeIcon
  }
})
export default class ExercisePreviewNavigationButton extends Vue {
  @Prop() text!: string;
  @Prop() icon!: string;
  @Prop({ default: 'white' }) color: string;
  @Prop({ default: false }) vertical: boolean;
  @Prop({ default: false }) iconFirst: boolean;

  @Emit()
  click (): void {}
}
