
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getPdfLinks, PdfLink } from '@/shared/data/pdf-links';
import { GTLinkTypes, ROLES } from '@/config';
import { CookieName } from '@/core/cookies';
import axios from 'axios';
import { namespace } from 'vuex-class';
import { NeSpinner } from '@ne/ne-vue2-lib';
import { USER_NOTIFY } from '@/store/list';
import { Notification, NotificationConfig, TestModel, TestTypeIdEnum } from '@/models';
import { categoryInfo } from '@/helpers/category-key-info';
import { CategoryInfoLevel } from '@/models/category-info';
import { CLogLevel, ContentType, EventName } from '@/models/gtm';
import { contentSetInfo } from '@/helpers/content-set-info';
import { testActivityInfo } from '@/helpers/activity-info';
import { getPdfSubType } from '@/helpers/gtm';
import { LayoutModel } from '@/store/layout-store/types';
import { isConsumerRole } from '@/helpers/roles';

const TestStore = namespace('TestStore');
const AppStore = namespace('AppStore');
const AuthStore = namespace('AuthStore');
const LayoutStore = namespace('LayoutStore');

@Component({
  name: 'DownloadTestLinks',
  components: { FileIcon: () => import('@/shared/components/FileIcon/FileIcon.vue'), NeSpinner }
})
export default class DownloadTestLinks extends Vue {
  @Prop({ default: [] }) files: string[];
  @Prop({ default: '' }) testTitle: string;
  @Prop({ default: false }) isPreviewModal: boolean;
  @Prop({ default: false }) isRedactorGt: boolean;
  @Prop({ default: false }) isOndorio: boolean;

  @TestStore.State('testPreView') testPreview: TestModel;
  @AppStore.Action(USER_NOTIFY) notify: (data: NotificationConfig) => Promise<Notification>;
  @AppStore.State('categoryKey') categoryKey: string;
  @AuthStore.State('isUserLogged') isUserLogged: boolean;
  @LayoutStore.State('layout') layout: LayoutModel;
  @TestStore.State('isAnswerCardNotDownloadable') isAnswerCardNotDownloadable: boolean;

  isDownloading = false;
  pdfLinks: PdfLink[] = [];
  gTLinkTypes = GTLinkTypes;

  mounted () {
    this.pdfLinks = getPdfLinks(this.files, this.$cookies.get(CookieName.USER_HASH), this.$apiURL, this.testPreview.generatorTestTypeId).sort((a, b) => a.order - b.order);
  }

  get isTeacher (): boolean {
    return this.layout.user!.role.includes(ROLES.TEACHER);
  }

  get isAnyLinkVisible () {
    const visibilityArray = this.pdfLinks.map(item => this.isLinkVisible(item));
    return visibilityArray.includes(true);
  }

  isLinkVisible (file: PdfLink): boolean {
    const isTestType = this.testPreview.generatorTestTypeId === TestTypeIdEnum.TEST;
    switch (file.type) {
    case GTLinkTypes.ZIP:
      return this.testPreview.attachments.length > 0;
    case GTLinkTypes.ANSWER_CARD:
      return this.isOndorio && !this.isAnswerCardNotDownloadable && !this.testPreview.earlySchoolEducation && isTestType && this.isTeacher;
    case GTLinkTypes.ECO:
      return isTestType;
    case GTLinkTypes.ANSWER_KEY:
      return true;
    case GTLinkTypes.NORMAL:
      return true;
    case GTLinkTypes.NO_SCORING:
      return isTestType;
    default:
      return !isConsumerRole(this.layout.user!.role);
    }
  }

  async downloadFile (file: PdfLink) {
    this.toggleDownloadingFlag(file, true);
    this.gtmDownloadResource(file.type);
    try {
      const response = await axios(file.link, { responseType: 'blob' });
      if (response.request.responseURL && response.request.responseURL.includes('session-expired')) {
        window.location.href = response.request.responseURL;
        return;
      }
      const fileName = `${this.testTitle} ${file.icon.fileName}`;
      this.resolvePdfFile(response.data, fileName);
      this.toggleDownloadingFlag(file, false);
    } catch (e) {
      await this.notify({ msg: this.$tc('NOTIFICATIONS.something_went_wrong'), type: 'error' });
      this.toggleDownloadingFlag(file, false);
    }
  }

  toggleDownloadingFlag (file: PdfLink, value: boolean) {
    this.isDownloading = value;
    file.isDownloading = value;
  }

  resolvePdfFile (file: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    (link as any).click(function (event: any) {
      event.preventDefault();
    });
  }

  gtmDownloadResource (type: GTLinkTypes) {
    setTimeout(() => {
      this.$piwik.push({
        bookyear: categoryInfo(this.categoryKey, CategoryInfoLevel.BOOKYEAR),
        c_log_level: CLogLevel.METHOD,
        chapter: null,
        content_author: this.testPreview.author,
        content_difficulty: null,
        content_id: this.testPreview.id ? this.testPreview.id.toString() : null,
        content_name: this.testPreview.title,
        content_set: contentSetInfo(this.testPreview.variants),
        content_status: testActivityInfo(this.testPreview.published),
        content_subtype: getPdfSubType(type),
        content_type: ContentType.TEST,
        content_version: this.testPreview.dateModify,
        education_level: categoryInfo(this.categoryKey, CategoryInfoLevel.EDUCATION_LEVEL),
        event: EventName.DOWNLOAD_RESOURCE,
        method: categoryInfo(this.categoryKey, CategoryInfoLevel.METHOD),
        page_path: this.$route.path,
        section: null,
        subject: categoryInfo(this.categoryKey, CategoryInfoLevel.SUBJECT)
      });
    });
  }
}
