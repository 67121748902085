import { AnswersType, AnswerType, BooleanAnswerType } from '@/models/exercises';

export const answersTypes: AnswerType[] = [
  {
    id: AnswersType.SINGLE_CHOICE,
    text: 'jednokrotny wybór'
  },
  {
    id: AnswersType.MULTIPLE_CHOICE,
    text: 'wielokrotny wybór'
  },
  {
    id: AnswersType.BOOLEAN,
    text: 'prawda&#x2010;fałsz',
    options: {
      english: [
        {
          id: 0,
          title: 'T | F',
          templates: [{ label: 'T', value: BooleanAnswerType.TRUE }, { label: 'F', value: BooleanAnswerType.FALSE }]
        },
        {
          id: 1,
          title: 'T | F | DS',
          templates: [
            { label: 'T', value: BooleanAnswerType.TRUE },
            { label: 'F', value: BooleanAnswerType.FALSE },
            { label: 'DS', value: BooleanAnswerType.DS }
          ]
        }
      ],
      german: [{ label: 'R', value: BooleanAnswerType.TRUE }, { label: 'F', value: BooleanAnswerType.FALSE }],
      default: [{ label: 'P', value: BooleanAnswerType.TRUE }, { label: 'F', value: BooleanAnswerType.FALSE }]
    }
  },
  {
    id: AnswersType.BUNDLES,
    text: 'wiązka jednokrotny wybór'
  },
  {
    id: AnswersType.BUNDLES_EXAM,
    text: 'wiązka zadanie maturalne'
  },
  {
    id: AnswersType.JUSTIFICATION,
    text: 'na uzasadnianie'
  },
  {
    id: AnswersType.ASSIGNMENT,
    text: 'przyporządkowanie'
  },
  {
    id: AnswersType.OPEN,
    text: 'otwarte'
  },
  {
    id: AnswersType.FLASHCARD,
    text: 'fiszka'
  },
  {
    id: AnswersType.CLOSED,
    text: 'zamknięte'
  },
  {
    id: AnswersType.OPEN_CLOSED,
    text: 'otwarto&#x2010;zamknięte'
  }
];
