
import Vue, { PropOptions } from 'vue';
import { NeWidgetReversibleTiles } from '@ne/ne-vue2-lib';
import { Banners, ReversibleTiles, Widget } from '@ne/ne-mul-interface-api';

const widgetReversibleTilesResponsiveOptionsMock = [
  {
    breakpoint: 320,
    leadMaxHeight: 73,
    hoverBgColor: 'white',
    height: 411,
    titleMaxHeight: 40,
    leadLineClampNumber: 4
  },
  {
    breakpoint: 768,
    leadMaxHeight: 73,
    hoverBgColor: 'white',
    height: 419,
    titleMaxHeight: 48,
    leadLineClampNumber: 4
  },
  {
    breakpoint: 1200,
    leadMaxHeight: 162,
    hoverBgColor: 'primary',
    height: 284,
    titleMaxHeight: 48,
    leadLineClampNumber: 9
  }
];

export default Vue.extend({
  name: 'WidgetReversibleTiles',
  components: {
    NeWidgetReversibleTiles
  },
  props: {
    data: {
      type: Object,
      required: true
    } as PropOptions<Widget<ReversibleTiles>>,
    lineColor: {
      type: String,
      default: 'grey'
    } as PropOptions<string>,
    lineIconColor: {
      type: String,
      default: 'primary'
    } as PropOptions<string>,
    icon: {
      type: String,
      default: 'arrow-bottom'
    } as PropOptions<string>,
    colsSm: {
      type: Number,
      default: 6
    } as PropOptions<number | null>,
    sectionColor: {
      type: String,
      default: 'white-darken-1'
    } as PropOptions<string>,
    columnCount: {
      type: Number,
      default: 4
    } as PropOptions<number>,
    moreLinkTitle: {
      type: String,
      default: 'zobacz więcej'
    } as PropOptions<string>,
    roundedSmall: {
      type: Boolean,
      default: false
    } as PropOptions<boolean>,
    bgColor: {
      type: String,
      default: 'white'
    } as PropOptions<string>,
    imageHeight: {
      type: Number,
      default: 207
    } as PropOptions<number>,
    imageWidth: {
      type: Number,
      default: 270
    } as PropOptions<number>,
    subtitleMaxHeight: {
      type: Number,
      default: 16
    } as PropOptions<number>
  },
  data () {
    return {
      itemsInFirstView: +this.data.attributes.itemsInFirstView,
      responsiveOptions: widgetReversibleTilesResponsiveOptionsMock
    };
  },
  computed: {
    bannersList (): Banners[] {
      return this.data.attributes?.banners || [];
    }
  }
});
