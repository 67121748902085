import { TestVariant } from '@/models';

// TODO - refactor: think about better performance
export const contentSetInfo = (variants: TestVariant[]) : string[] => {
  const contentSet : string[] = [];
  variants.forEach(function (variant) {
    if (!variant?.pages) {
      return;
    }
    variant.pages.forEach(function (pageList) {
      if (!pageList) {
        return;
      }
      pageList.forEach(function (page) {
        contentSet.push(`${page.id}_${page.variantId}`);
      });
    });
  });
  return contentSet;
};
