
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
// @ts-ignore
import {
  CHECK_COMMENTS_IN_CCOKIES,
  CLOSE_PREVIEW_MODAL,
  DISPLAY_RECREATED,
  EXERCISES_ADD,
  EXERCISES_REMOVE,
  GET_SELECTED_VARIANT,
  SET_SELECTED_VARIANTS,
  USER_NOTIFY
} from '@/store/list';
import { namespace } from 'vuex-class';
import { Exercise, ExerciseInTest, ExercisePreViewConfig, TestModel } from '@/models';
import { Active, ratioMap, ROLES } from '@/config';
import { getMaxPrintHeight } from '@/helpers';
import { ExerciseInTestModal } from '../ExerciseInTestModal';
import { DeleteExerciseModal } from '../DeleteExerciseModal';
import { LayoutModel, MenuModel } from '@/store/layout-store/types';
import FixUrlMixin from '@/mixins/FixUrlMixin';
import { mixins } from 'vue-class-component';
import { find } from '@/helpers/array-manipulation';
import { FileIcon } from '@/shared/components/FileIcon';
import { ActionButton } from '@/shared/components/ActionButton';
import getIconTypeMixin from '@/mixins/getIconTypeMixin';
import ExercisePreviewNavigationButton from './ExercisePreviewNavigationButton';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, Tooltip } from '@/shared/components';
import { NeIcon } from '@ne/ne-vue2-lib';
import { CookieName } from '@/core/cookies';
import {
  AnswersType,
  ExerciseToAdd,
  ExerciseVariant, SelectedVariant,
  Source
} from '@/models/exercises';
import { RouteName, RoutePath } from '@/router/models';
import { answerTypeText, isExerciseOldType, isTeacherVariantTypeTheSame } from '@/helpers/exercise';
import { isRedactorGroup, isTeacherGroup } from '@/helpers/roles';
import ExerciseComment from '@/shared/components/ExercisePreviewModal/ExerciseComment/ExerciseComment.vue';

const AppStore = namespace('AppStore');
const ExerciseStore = namespace('ExerciseStore');
const LayoutStore = namespace('LayoutStore');
const TestStore = namespace('TestStore');

@Component({
  name: 'ExercisePreviewModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    FileIcon,
    DeleteExerciseModal,
    ExerciseInTestModal,
    ActionButton,
    ExercisePreviewNavigationButton,
    ExerciseComment,
    NeIcon,
    Tooltip
  }
})

export default class ExercisePreviewModal extends mixins(getIconTypeMixin, FixUrlMixin) {
  @Prop() currentTest!: TestModel;
  @Prop() hideNextButton!: boolean;
  @Prop() hidePrevButton!: boolean;
  @Prop() isModalOpen: boolean;

  answerType = AnswersType;
  exerciseMaxPrintHeight = 0;
  exerciseToDeleteId = 0;
  groupItemName = Source;
  recalculateScroll = false;
  scaleFactor = ratioMap.exercisePreview;
  showDeleteExerciseModal = false;
  showExerciseInTestModal = false;
  isTeacherVariantTypeTheSame = isTeacherVariantTypeTheSame;

  @AppStore.State('lastStep') lastStep: number;
  @AppStore.State('userRole') userRole: ROLES[];
  @ExerciseStore.Getter(GET_SELECTED_VARIANT) getSelectedVariant!: (id:number) => number;
  @ExerciseStore.Mutation(SET_SELECTED_VARIANTS) selectVariant!: (selectedVariant: SelectedVariant) => void;
  @ExerciseStore.State('exerciseToPreview') currentPreview!: ExercisePreViewConfig;
  @LayoutStore.State('layout') layout: LayoutModel;
  @LayoutStore.State('menu') menu: MenuModel;

  get isEditDisabled () {
    return this.userRole.includes(ROLES.TEACHER) && this.exercise.author === Source.CKE;
  }

  get showOndorio (): boolean | null {
    return this.layout.user && this.layout.user.isOndorio;
  }

  get currentVariantIndex (): number {
    return this.getSelectedVariant(this.exercise.id);
  }

  get exercise (): Exercise {
    return this.currentPreview.exercise;
  }

  get currentVariant (): ExerciseVariant {
    return this.exercise.variants[this.currentVariantIndex];
  }

  get isEditEnabled (): boolean {
    return !(isRedactorGroup(this.userRole) && this.exercise.active === Active.PUBLISHED);
  }

  get exerciseAuthor (): string {
    return this.currentVariant.author ?? '';
  }

  get exerciseSkills (): string {
    const skills = this.currentPreview.exercise.skills;
    return skills ? skills.join('; ') : '';
  }

  get shouldDisplayTrash () {
    return this.exercise.canDelete;
  }

  get timePlusMin () {
    if (this.currentPreview && this.exercise && this.exercise.time) {
      return `${this.exercise.time} min`;
    }
    return '';
  }

  get inTest () {
    if (this.currentTest.variants && this.currentTest.variants.length) {
      return !!find(
        this.currentTest.variants[0].pages.reduce((acc: any, val: any) => acc.concat(val), []),
        this.exercise.id
      );
    } else {
      return false;
    }
  }

  get isUsedInAnyTest (): boolean {
    return this.exercise.exerciseInTests && this.exercise.exerciseInTests.length > 0;
  }

  get shouldDisplayAddBtn (): boolean {
    return this.exercise.active === Active.PUBLISHED;
  }

  get isTeacher () {
    return isTeacherGroup(this.userRole);
  }

  get exerciseToEditOrAdd (): ExerciseToAdd {
    return {
      exercise: {
        active: this.exercise.active,
        attachments: this.exercise.attachments,
        chapter: this.exercise.chapter ?? null,
        id: this.exercise.id,
        level: this.exercise.level ?? null,
        printHeight: this.exerciseMaxPrintHeight,
        score: this.exercise.score,
        task: this.currentVariant.content,
        time: this.exercise.time,
        title: this.exercise.title,
        section: this.exercise.section ?? null,
        variantId: this.currentVariant.id,
        variantNumber: this.currentVariantIndex
      }
    };
  }

  get comment (): string | null {
    if (!this.isTeacher) return this.exercise.comment;
    return isExerciseOldType(this.exercise.answersType) ? this.exercise.comment : null;
  }

  get exerciseVariantAnswers (): string | null {
    const answers = this.currentVariant.answers;
    return answers ? JSON.stringify(answers) : null;
  }

  get exerciseTypes (): string | null {
    return answerTypeText(this.exercise.answersType);
  }

  get isExerciseOldType (): boolean | null {
    return isExerciseOldType(this.exercise.answersType);
  }

  @AppStore.Action(USER_NOTIFY) notify: any;
  @AppStore.Mutation(DISPLAY_RECREATED) recreatedGroups: any;
  @ExerciseStore.Mutation(CLOSE_PREVIEW_MODAL) closePreview: any;
  @ExerciseStore.Action(CHECK_COMMENTS_IN_CCOKIES) reloadComments: any;
  @TestStore.Action(EXERCISES_ADD) addExercise: any;
  @TestStore.Action(EXERCISES_REMOVE) removeExercise: any;

  @Watch('currentTest', { deep: true })
  testUpdateHandler () {
    this.recalculateScroll = !this.recalculateScroll;
  }

  @Watch('currentPreview', { deep: true })
  currentPreviewUpdateHandler (preview: any) {
    if (preview) {
      this.calculateWrapper(preview.exercise);
    }
  }

  created () {
    this.exerciseMaxPrintHeight = 0;
    this.recalculateScroll = !this.recalculateScroll;
  }

  mounted () {
    const scarlett: Window = window;
    scarlett.addEventListener('resize', this.handleResize);
    this.exerciseMaxPrintHeight = 0;
    this.recalculateScroll = !this.recalculateScroll;
    this.handleResize();
  }

  destroyed () {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize () {
    this.scaleFactor = window.innerWidth < 1200 ? ratioMap.secondStep : ratioMap.exercisePreview;
  }

  navigateToTest (exerciseInTest: ExerciseInTest) {
    const categoryKey = `${exerciseInTest.categoryKey}#${exerciseInTest.categoryId},${exerciseInTest.hash}`;
    window.location.href = `${window.location.origin}${RoutePath.TEST_DATABASE}/${categoryKey}`;
  }

  checkEdit () {
    if (this.isUsedInAnyTest) {
      this.showExerciseInTestModal = true;
    } else {
      this.editExercise();
    }
  }

  editExercise () {
    this.onEditModalClose();
    if (this.$route.name !== RouteName.EXERCISE_DATABASE) {
      sessionStorage.setItem('testInEdition', JSON.stringify(this.currentTest));
    }
    this.$router.push({
      name: RouteName.EXERCISE_EDITOR,
      params: {
        exerciseId: this.exercise.id.toString() || '',
        variantIndex: (this.currentVariantIndex + 1).toString()
      }
    });
    this.editExerciseEvent(this.exerciseToEditOrAdd);
  }

  onEditModalClose () {
    this.showExerciseInTestModal = false;
  }

  changeVariant (index: number) {
    this.selectVariant({ exerciseId: this.exercise.id, variantIndex: index });
  }

  closeExercisePreviewModal () {
    this.$emit('close');
  }

  removeFromTest (): void {
    this.removeExercise(this.exercise);
  }

  onDeleteModalClose (): void {
    this.showDeleteExerciseModal = false;
    this.closePreview();
  }

  askDeleteModal (exerciseId: any): void {
    this.exerciseToDeleteId = exerciseId;
    this.showDeleteExerciseModal = !this.showDeleteExerciseModal;
  }

  calculateWrapper (exercise: any): void {
    if (exercise.variants) {
      const maxArray = exercise.variants.map((o: any) => parseInt(o.printHeight, 10));
      this.exerciseMaxPrintHeight = getMaxPrintHeight(maxArray);
    }
  }

  fileUrlWithHash (url: string): string {
    const userHash = this.$cookies.get(CookieName.USER_HASH);
    return `${url},${userHash}`;
  }

  @Emit()
  addToTest (): ExerciseToAdd {
    return this.exerciseToEditOrAdd;
  }

  @Emit() editExerciseEvent (payload: any): any {
    return payload;
  }

  @Emit() exerciseDeleted () {}

  @Emit() changeExercise (step: number): number {
    return step;
  }
}
