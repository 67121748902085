
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TreeNode } from '@/models/structure-mapping';
import { FacetsContainer } from '@/models/facets';
import { getFlattenTree } from '@/helpers/facets';

@Component({
  name: 'RecursionTree'
})
export default class RecursionTree extends Vue {
  @Prop() children?: TreeNode[] | null;
  @Prop() isExerciseFilters: boolean;
  @Prop({ required: true }) node: TreeNode | null;
  @Prop() checkedId: number | string;
  @Prop({ required: true }) lvl: number;
  @Prop({ default: false }) parentUnderlined: boolean;
  @Prop() facets: FacetsContainer;
  @Prop({ default: false }) isStructureTree: boolean;

  get checked (): boolean {
    return !!(this.node && this.node.id === this.checkedId);
  }

  get itemClass (): Record<string, any> {
    return {
      'gt-recursion-tree__item--first-ancestor': this.lvl === 1,
      'gt-recursion-tree__item--first-ancestor-hidden': this.node!.id === 14,
      'gt-recursion-tree__item--checked': this.checked,
      'gt-recursion-tree__item--margin-left': this.lvl > 2
    };
  }

  get hasChildren (): boolean {
    return !!(this.node && this.node.children && this.node.children.length);
  }

  get isParentUnderlined (): boolean {
    return this.lvl === 1 && this.parentUnderlined && this.hasChildren;
  }

  isParentExpanded (node: TreeNode) {
    const flattenTree = getFlattenTree(this.facets.children);
    const parentNode = flattenTree.find(item => `${item?.key}-${item?.id}` === node.parentId);
    return this.lvl !== 1 ? parentNode?.expanded : true;
  }
}
