
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ValidationTooltip'
})
export default class ValidationTooltip extends Vue {
  @Prop()
  messages!: string[];

  @Prop({
    default: 'info',
    type: String,
    validator: (val: string) => {
      return [
        'info',
        'success',
        'warning',
        'danger'
      ].indexOf(val) !== -1;
    }
  })
  type!: string;

  hover = false;

  get classes (): any[] {
    return [`gt-validation-tooltip__card--${this.type}`, { 'gt-validation-tooltip__card--opened': this.hover }];
  }
}
